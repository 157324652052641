<template>
  <v-app>
    <v-alert
      :value="alert"
      prominent
      dense
      border="left"
      colored-border
      :color="alert_color"
      transition="slide-y-transition"
      dismissible
      style="position: fixed; z-index: 2"
    >
      <v-row align="center">
        <v-col class="grow">
          <v-avatar :color="alert_color">
            <v-icon color="white">{{ alert_icon }}</v-icon>
          </v-avatar>
          {{ alert_text }}
        </v-col>
      </v-row>
    </v-alert>
    <v-row
      class="
        fill-height
        flex-column
        justify-center
        align-center
        indigo
        darken-4
      "
    >
      <br />
      <v-card
        width="500"
        dark
        class="mx-auto pt-10 pa-10"
        elevation="8"
        rounded="xl"
      >
        <v-img src="@/assets/logo_kedas.png"></v-img>
        <v-card-title class="justify-center" style="font-size: 1.5rem"
          >Provide Your Credentials</v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="User ID"
              prepend-inner-icon="mdi-account-circle"
              v-model="email"
              :rules="emailRule"
              color="light-blue accent-1"
              outlined
              rounded
            ></v-text-field>
            <v-text-field
              label="Password"
              prepend-inner-icon="mdi-lock"
              append-icon="mdi-eye"
              :type="fieldType"
              v-model="password"
              :rules="passwordRule"
              color="light-blue accent-1"
              outlined
              rounded
              @keyup.enter="handleLogin"
              @click:append="showPass"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-3">
          <v-btn
            block
            rounded
            depressed
            large
            class="black--text"
            color="light-blue accent-1"
            :loading="loginLoading"
            :disabled="loginLoading"
            @click="handleLogin"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} —
          <strong>PT. MAHAPURNA MANDIRI UTAMA</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<style>
</style>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "auth",
  data() {
    return {
      alert: false,
      alert_color: "",
      alert_icon: "",
      alert_text: "",
      email: "",
      emailRule: [(v) => !!v || "Email Required"],
      password: "",
      passwordRule: [(v) => !!v || "Password Required"],
      fieldType: "password",
      show_password: false,
      valid: true,
      loginLoading: false,
      complete: false,
    };
  },
  methods: {
    async handleLogin() {
      let vm = this;
      this.loginLoading = true;
      this.$refs.form.validate();
      if (this.valid === false) {
        this.loginLoading = false;
        this.alert = true;
        this.alert_color = "warning";
        this.alert_icon = "mdi-alert-outline";
        this.alert_text = "Please fill all the required fields";
        setTimeout(() => (this.alert = false), 2000);
      } else {
        await axios
          .get(`${process.env.VUE_APP_KB_API_URL}/sanctum/csrf-cookie`)
          .then((response) => {
            axios
              .post(`${process.env.VUE_APP_KB_API_URL}/api/login`, {
                email: this.email,
                password: this.password,
              })
              .then((response) => {
                console.log(response.data);
                window.localStorage.setItem("token", response.data[0].token);
                window.localStorage.setItem("name", response.data[0].user.name);
                console.log("User signed in!");
                vm.loginLoading = false;
                vm.alert = true;
                vm.alert_color = "success";
                vm.alert_icon = "mdi-check";
                vm.alert_text = "Login Approved! Redirecting to Dashboard. . .";
                setTimeout(() => {
                  this.$router.push({ name: 'Main', query: { redirect: '/dashboard/main' } }),
                    1500;
                });
              })
              .catch((err) => {
                console.log(err.response.status);
                vm.loginLoading = false;
                vm.alert = true;
                vm.alert_color = "error";
                vm.alert_icon = "mdi-alert-circle-outline";
                vm.alert_text = "Bad Credentials! Please check again";
                setTimeout(() => (vm.alert = false), 2000);
              });
            console.log(response.status);
          })
          .catch(function (error) {
            console.log(error.response.status);
          });
        this.$router.push({
          path: "/dashboard/main",
        });
      }
    },
    showPass(){
      this.show_password = !this.show_password
      if(this.show_password === false){
        this.fieldType = 'password'
      } else [
        this.fieldType = 'text'
      ]
    }
  },
  created() {
    window.localStorage.removeItem("token");
  },
};
</script>
